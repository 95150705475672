<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-12">
        <div>
          <button
            type="button"
            class="btn btn-success mb-3"
            @click="onCreateRoom"
          >
            <i class="mdi mdi-plus me-1"></i> สร้างพื้นที่
          </button>
        </div>
        <div
          class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline"
        >
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center fw-normal">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-end"
              >
                <label class="d-inline-flex align-items-center fw-normal">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ms-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <b-table
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-transparent"
            :items="tableData"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #head(name)="">
              <span>ชื่อพื้นที่</span>
            </template>
            <template #head(floor)="">
              <span>ชั้น</span>
            </template>
            <template #head(description)="">
              <span>คำอธิบาย</span>
            </template>
            <template #head(capacity)="">
              <span>ความจุ/คน</span>
            </template>
            <template #head(size)="">
              <span>ขนาดพื้นที่/ตารางเมตร</span>
            </template>
            <template #head(price)="">
              <span>อัตราค่าเช่า บาท/วัน</span>
            </template>
            <template #head(ismaintenance)="">
              <span>พื้นที่พร้อมใช้งาน</span>
            </template>
            <template #head(action)="">
              <span>ตั้งค่า</span>
            </template>

            <template #cell(capacity)="data">
              {{ data.value.toLocaleString() }}
            </template>
            <template #cell(size)="data">
              {{ data.value.toLocaleString() }}
            </template>
            <template #cell(price)="data">
              {{ data.value.toLocaleString() }}
            </template>
            <template #cell(ismaintenance)="data">
              <span
                class="badge bg-soft-success font-size-12 ms-2"
                v-if="!data.value"
              >
                <i class="mdi mdi-check"></i
              ></span>
              <span
                class="badge bg-soft-danger font-size-12 ms-2"
                v-if="data.value"
              >
                <i class="mdi mdi-close"></i
              ></span>
            </template>

            <template v-slot:cell(action)="row">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <b-button
                    pill
                    variant="outline-primary"
                    @click="onUpdateAreaItem(row.item)"
                  >
                    <i class="uil uil-pen font-size-18"></i
                  ></b-button>
                </li>
                <li class="list-inline-item">
                  <b-button
                    pill
                    variant="outline-danger"
                    @click="onRemoveAreaItem(row.item)"
                    ><i class="uil uil-trash-alt font-size-18"></i
                  ></b-button>
                </li>
              </ul>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        :id="infoModal.id"
        :title="infoModal.title"
        ok-only
        @hide="resetInfoModal"
      >
        <pre>{{ infoModal.content }}</pre>
      </b-modal>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Orders component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Room management",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  created() {
    const filters = {};
    this.$store.dispatch("areaspecify/getAreaLists", filters);
  },
  data() {
    return {
      title: "การจัดการพื้นที่",
      items: [
        {
          text: ""
        },
        {
          text: "",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      fields: [
        {
          headerTitle: "ชั้น",
          key: "floor",
          sortable: true
        },
        {
          headerTitle: "ชื่อ",
          key: "name",
          sortable: true
        },
        {
          key: "description",
          sortable: true
        },
        {
          key: "capacity",
          sortable: true
        },
        {
          key: "size",
          sortable: true
        },
        {
          key: "price",
          sortable: true
        },
        {
          key: "ismaintenance",
          sortable: true
        },

        { key: "action", label: "Actions" }
      ]
    };
  },
  middleware: "authentication",
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      if (this.tableData) {
        return this.tableData.length;
      }
      return 0;
    },
    tableData() {
      return this.$store ? this.$store.state.areaspecify.areaLists : [];
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onCreateRoom() {
      this.$router.push({
        path: "/roommanagement/create"
      });
    },
    infotest(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onRemoveAreaItem(item) {
      let currentState = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure to delete this item?")
        .then(value => {
          if (value) {
            currentState.$store.dispatch("areaspecify/removeArea", item.id);
          }
        })
        .catch(err => err);
    },
    onUpdateAreaItem(item) {
      this.$store.dispatch("areaspecify/setUpdateAreaSelected", item);
      this.$router.push({
        path: "/roommanagement/update"
      });
    }
  }
};
</script>
